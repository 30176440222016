/*Main*/
html {
    overflow: auto;
    cursor: url(https://en.cursor.style/cursors/1832-louise-belcher-cursor.svg), default !important;
}

body {
    background-color: #2244CC;
    background-image: url("./imgs/redwoods.jpg");
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: Center Center;
    cursor: url(https://en.cursor.style/cursors/1832-louise-belcher-cursor.svg), default !important;

}

#main {
    background:transparent;
    font-family: arial;
    font-size: 14px;
    color: #000000;
    text-align: justify;
    width: 70%;
    padding: 10px;
    margin: 0 auto;
}

#main-left-col {
    width: 25%;
    float: left;
    position: fixed;
}

#main-right-col {
    width: 60%;
    float: right;
}

#tiles {
    background-color: rgba(255, 199, 187, 0.5);
    border: 1px solid #213363;
    margin: 10px auto;
    -moz-border-radius: 4px; /* Firefox */
    -webkit-border-radius: 4px; /* Safari and Chrome */
    border-radius: 4px; /* Standard: For modern browsers */
}

#tile-body h3 {
    color: #ffffff;
    margin: 0px;
    background-color: #213363;
}

#tile-title {
    background: #213363;
    border: #213363;
    border-width: thick;
    padding: 10px;
}

#tile-body th {
    background: #213363;
    border: #213363;
    border-width: thick;
    padding: 10px;
    width: 100%;
}

#tile-body td {
    padding: 5px;
}

#tile-table {
    width: 100%;
    margin: 0 auto;
}

a:link {
    font-weight: bold;
    text-decoration: none;
    color: #698CBC;
}

a:visited {
    color: #698CBC;
}

a:hover {
    color: hotpink;
}

a:active {
    color: #be03fc;
}

/*About Me*/
.about-me-container {
    float: left;
}

#about-me {
    width: 100%;
    padding: 10px;
    margin: 0 auto;
}

#default-pic-col {
    width: 50%;
    height: auto;
}

.default-pic img {
    width: 90%;
    height: auto;
}

#asl-info-col {
    width: 50%;
    height: auto;
}

#asl-info-col img {
    width: 100%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 5px;
}

#mood {
    text-align: left;
    width: 100%;
}

/*Contact*/
#contact-email img {
    width: 5%;
    padding-top: 2px;
    padding-right: 10px;
    text-align: left;
}

#contact-email a {
    font-size: 18px;
    text-align: justify;
    vertical-align: top;
}

#contact-linkedin img {
    width: 5%;
    padding-top: 2px;
    padding-right: 10px;
    text-align: left;
}

#contact-linkedin a {
    font-size: 18px;
    text-align: justify;
    vertical-align: top;
}

#contact-insta img {
    width: 5%;
    padding-top: 2px;
    padding-right: 10px;
    text-align: left;
}

#contact-insta a {
    font-size: 18px;
    text-align: justify;
    vertical-align: top;
}

/*Blurb*/
#blurb-body p {
    padding: 0px 10px;
}